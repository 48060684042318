import { booking } from './booking';
import { cars } from './cars';
import { seo } from './seo';
import { user } from './user';

export const api = {
  booking,
  cars,
  user,
  seo,
};
