import axios from 'axios';

import { LocaleType, PageDataResponse, SeoData } from '@/types';

export const seo = {
  getSeoPageData: async ({ slug, locale, carId }: { slug?: string; locale?: LocaleType; carId?: string }) => {
    const response = await axios.get<SeoData>(`/api/v1/site_content/seo_context`, {
      params: {
        page_name: slug,
        language: locale,
        car: carId,
      },
    });

    return response.data;
  },
  getPageData: async ({ locale, pageName }: {
    pageName: string,
    locale?: LocaleType,
  }) => axios.get<PageDataResponse>(`/api/v1/site_content/blocks`, {
    params: {
      page_name: pageName,
      language: locale,
    }
  })
};
