import axios, { AxiosResponse } from 'axios';

import {
  Car,
  CarCalendar,
  CarFilters,
  ExtraServiceType,
  LocaleType,
  PlaceType,
  PriceData,
  ServerExtraService,
} from '@/types';
import { prepareExtraServices } from '@/utils';

export const cars = {
  getCars: async ({ locale }: { locale: LocaleType }) => {
    const response = await axios.get<AxiosResponse<Car[]>>('/api/v1/cars/', {
      params: {
        language: locale,
      },
    });

    return response.data;
  },
  getCarFilters: async () => {
    const response = await axios.get<CarFilters>('/api/v1/cars/filters');

    return response.data;
  },
  getCar: async (id: string, locale: LocaleType) => {
    const response = await axios.get<Car>(`/api/v1/cars/${id}`, { params: { language: locale } });

    return response.data;
  },
  getCarAvailabilityCalendar: async ({
    carId,
    startDate,
    endDate,
  }: {
    carId: string;
    startDate?: string;
    endDate?: string;
  }) => {
    const response = await axios.get<AxiosResponse<CarCalendar[]>>(`/api/v1/cars/${carId}/calendar`, {
      params: { start_date: startDate, end_date: endDate },
    });

    return response.data;
  },

  getCarsForRent: async ({
    startDate,
    startTime,
    endDate,
    endTime,
    color,
    bodyType,
    locale,
    currency,
  }: {
    startDate?: string | null;
    startTime?: string | null;
    endDate?: string | null;
    endTime?: string | null;
    color?: string | null;
    bodyType?: string | null;
    locale?: LocaleType | null;
    currency?: string | null;
  }) => {
    const response = await axios.get<AxiosResponse<Car[]>>('/api/v1/cars/for_rent', {
      params: {
        start_date: startDate,
        start_time: startTime,
        end_date: endDate,
        endTime: endTime,
        color,
        body_type: bodyType,
        language: locale,
        currency,
      },
    });

    return response.data;
  },
  getExtraServicesInfo: async ({ locale }: { locale: LocaleType }) => {
    const response = await axios.get<AxiosResponse<ExtraServiceType[]>>('/api/v1/extra_services/all', {
      params: {
        language: locale,
      },
    });

    return response.data;
  },
  calculateRentCost: async ({
    carId,
    startDate,
    startTime,
    endDate,
    endTime,
    addressToDeliverGeo,
    addressToPickupGeo,
    extraServices = {},
  }: {
    startDate?: string | null;
    startTime?: string | null;
    endDate?: string | null;
    endTime?: string | null;
    carId?: string | null;
    addressToDeliverGeo?: PlaceType | null;
    addressToPickupGeo?: PlaceType | null;
    extraServices?: { [key: string]: ServerExtraService } | null;
  }) =>
    axios.post<PriceData>('/api/v1/clients/me/rentings/calc_price', {
      start_date: startDate,
      start_time: startTime,
      end_date: endDate,
      end_time: endTime,
      car: carId,
      address_to_deliver: addressToDeliverGeo?.info?.description,
      address_to_pickup: addressToPickupGeo?.info?.description,
      extra_services: prepareExtraServices(extraServices),
    }),
};
