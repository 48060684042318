export type Nullable<T> = T | null;

export type User = {
  access_token: string;
};

export enum VerifyStatuses {
  VERIFIED = 'valid',
  NOT_VERIFIED = 'new',
  INVALID = 'invalid',
  UNDER_REVIEW = 'on_verification',
  OUTDATED = 'outdated',
}

export type PhoneData = {
  phone: string;
  isPhoneConfirmed: boolean;
};

export type RegisterUser = {
  clientType: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  postIndex: string;
  country: string;
  city: string;
  address: string;
  language: string;
  password: string;
};

export type ImageCategories = {
  category: string;
  image: string;
  image_url: string;
};

export type CarTariff = {
  currency: string;
  id?: string;
  name: string;
  period: number;
  price: number;
};

export enum BodyType {
  'suv' = 'suv',
}

export type Car = {
  id: string;
  slug: string;
  image_categories: ImageCategories[];
  model: {
    id: string;
    brand_name: string;
    model_name: string;
    transmission_type: string;
    body_type: BodyType;
    description: string;
    image: string;
    image_url: string;
  };
  release_year: number;
  registration_number: string;
  ecology_class: string;
  latitude: number;
  longitude: number;
  fuel_type: string;
  description: string;
  price_without_discount: number;
  min_price: number;
  is_promotion: boolean;
  total_selected_dates_price: number;
  color: string;
  mileage_limit: number;
  drivetrain: string;
  fuel_consumption: number;
  engine_displacement: number;
  engine_power: number;
  seats_count: number;
  doors_count: number;
  has_gps: boolean;
  clearance: number;
  tariffs: CarTariff[];
  currency: string;
};

export type CarFilters = {
  body_type: BodyType[];
  color: string[];
};

export type CarFiltersPrepared = {
  bodyTypes: BodyType[];
  colors: string[];
};

export enum ErrorCode {
  email_exist = 'email_exist',
  phone_exist = 'phone_exist',
  account_terminated = 'account_terminated',
  login_error = 'login_error',
  account_not_found = 'account_not_found',
  phone_too_many_requests = 'phone_too_many_requests',
  phone_confirmed = 'phone_confirmed',
  phone_service_unavailable = 'phone_service_unavailable',
  account_suspended = 'account_suspended',
  object_not_found = 'object_not_found',
  car_not_available = 'car_not_available',
  tariff_not_found = 'tariff_not_found',
  extra_service_not_found = 'extra_service_not_found',
  extra_service_max_amount_exceeded = 'extra_service_max_amount_exceeded',
  file_extension_not_allowed = 'file_extension_not_allowed',
  already_verified = 'already_verified',
  already_on_verification = 'already_on_verification',
  incomplete_package_for_verification = 'incomplete_package_for_verification',
}

export enum LocaleType {
  en = 'en',
  fr = 'fr',
}

export type DocumentMedia = {
  created_at: 'string';
};

export enum DocumentType {
  DriverLicenseBack = 'driver_license_back',
  DriverLicenseFront = 'driver_license_front',
  Passport = 'passport',
}

export type Document = {
  created_at: string;
  document_number: string;
  document_status: VerifyStatuses;
  document_type: DocumentType;
  id: string;
  is_active: boolean;
  issued_at: string;
  media_files: DocumentMedia[];
  valid_till: string;
};

export type ErrorMessage = `errors.${ErrorCode}`;

export type SeoData = {
  car: string;
  description: string;
  id: string;
  keywords: string;
  language: string;
  page_name: string;
  title: string;
};

export type Booking = {
  car?: Car | null;
  endDate?: string;
  endTime?: string;
  extraServices?: {
    [key: string]: ServerExtraService;
  };
  startDate?: string;
  startTime?: string;
  deliverLatitude?: number | null;
  deliverLongitude?: number | null;
  pickupLatitude?: number | null;
  pickupLongitude?: number | null;
  addressToDeliverGeo?: PlaceType | null;
  addressToPickupGeo?: PlaceType | null;
  carPrice?: number | null;
  carCurrency?: string | null;
  bodyType?: BodyType | null;
  color?: string | null;
  isOrderCreated?: boolean;
};

export type MainTextMatchedSubstrings = {
  offset: number;
  length: number;
};

export type StructuredFormatting = {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings: MainTextMatchedSubstrings[];
};

export type PlaceInfo = {
  place_id: string;
  description: string;
  structured_formatting: StructuredFormatting;
};

export type PlaceType = {
  info: PlaceInfo | null;
  latitude: number | null;
  longitude: number | null;
};

export type RentLocationAndTime = {
  start_date: string;
  start_time: string;
  end_date: string;
  end_time: string;
  addressToDeliver: string;
  addressToPickup: string;
  deliver_latitude: number;
  deliver_longitude: number;
  pickup_latitude: number;
  pickup_longitude: number;
};

export type ExtraServiceType = {
  id: string;
  name: string;
  description: string;
  price: number;
  original_price: number;
  currency: string;
  max_per_rent: number;
};

export type ServerExtraService = {
  id: string;
  count: number;
  sum: number;
  name: string;
  currency: string;
};

export type BookingExtraService = {
  price?: number;
  amount?: number;
};

export type StoredExtraService = ExtraServiceType & ServerExtraService & BookingExtraService;

export type RentalObject = RentLocationAndTime & {
  id: string;
  status: string;
  car: {
    id: string;
    slug: string;
    model: {
      id: string;
      slug: string;
      brand_name: string;
      model_name: string;
      transmission_type: string;
      body_type: BodyType;
      description: string;
      image: string;
      image_url: string;
    };
    release_year: number;
    ecology_class: string;
    fuel_type: string;
    description: string;
    color: string;
    mileage_limit: number;
    drivetrain: string;
    fuel_consumption: number;
    engine_displacement: number;
    engine_power: number;
    seats_count: number;
    doors_count: number;
    has_gps: boolean;
    clearance: number;
  };
  startDate: string;
  endDate: string;
  startTime: string;
  endTime: string;
  address_to_deliver: string;
  address_to_pickup: string;
  tariff: null;
  total_price: number;
  extra_services: StoredExtraService[];
  created_at: string;
};

export enum BookingItemType {
  Current = 'current',
  Past = 'past',
  Future = 'future',
}

export type RentalResponse = {
  limit: number;
  offset: number;
  total: number;
  data: RentalObject[];
};

export type CarCalendar = {
  date: string;
  is_available: boolean;
};

export enum GoogleMapLoadStatus {
  LOADED = 'loaded',
  IN_PROGRESS = 'in_progress',
  FAILED = 'failed',
  UNDEFINED = 'undefined',
}

export type PriceData = {
  currency: string;
  extra_services: ServerExtraService[];
  tariff: string;
  total_price: number;
  car_price: number;
};

export enum LoginPageStatus {
  Login = 'login',
  Register = 'register',
}

export enum UploadingState {
  ERROR = 'error',
  LOADING = 'loading',
  LOADED = 'loaded',
  NOT_ASKED = 'not_asked',
}

export enum DocumentState {
  VERIFIED = 'valid',
  NOT_VERIFIED = 'new',
  INVALID = 'invalid',
  UNDER_REVIEW = 'on_verification',
  OUTDATED = 'outdated',
  ERROR = 'error',
  LOADING = 'loading',
  LOADED = 'loaded',
}

export const assertNever = (x: never): never => {
  throw new Error('Unexpected type: ' + x);
};

export type RentalState = {
  addressToDeliver?: string;
  addressToPickup?: string;
  startDate?: string;
  startTime?: string;
  endDate?: string;
  endTime?: string;
  pickupLatitude?: number;
  pickupLongitude?: number;
  deliverLatitude?: number;
  deliverLongitude?: number;
};

export enum EventMessageType {
  ChatMessage = 'chat_message',
  ClientVerified = 'client_verified',
  ClientVerificationDenied = 'client_verification_denied',
  CarRented = 'car_rented',
  CarRentingCanceled = 'car_renting_cancelled',
  CarRentingConfirmationCancelled = 'car_renting_confirmation_cancelled',
}

export type NotificationMessagePayload =
  | {
    action:
    | EventMessageType.ChatMessage
    | EventMessageType.ClientVerified
    | EventMessageType.ClientVerificationDenied;
  }
  | {
    action:
    | EventMessageType.CarRented
    | EventMessageType.CarRentingCanceled
    | EventMessageType.CarRentingConfirmationCancelled;
    payload: {
      car_id: string;
      renting_id: string;
    };
  };

export type NotificationMessage = {
  id: string;
  event: 'notification';
  is_read: boolean;
  created_at: string;
  retry: number;
} & NotificationMessagePayload;

export type PageData = {
  "id": "string",
  "page_name": "string",
  "block_name": "string",
  "car": "string",
  "language": string
  "content": "string",
  "description": "string",
  "image": "string",
  "image_url": "string"
}

export type PageDataResponse = {
  data: {
    data: PageData[]
  }
}