import axios, { AxiosPromise, AxiosResponse } from 'axios';

import { WebSocketUploadUrl } from '@/constants/api';
import { Document } from '@/types';

export type ResponseUser = {
  id: string;
  client_type: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  post_index: string;
  country: string;
  city: string;
  address: string;
  language: string;
  currency: string;
  verification_status: string;
  is_email_confirmed: true;
  is_phone_confirmed: true;
  registered_at: string;
};

export const refreshTokenUrl = '/api/v1/clients/refresh_token';

export const user = {
  loginWithEmail: async ({ login, password }: { login: string; password: string }) => {
    const response = await axios.post('/api/v1/clients/login', {
      login,
      password,
    });

    return response.data;
  },

  registerWithEmail: async ({
    clientType = 'private',
    firstName,
    lastName,
    email,
    phone,
    postIndex = 'postIndex',
    country,
    city,
    address,
    language,
    password,
    test = false,
  }: {
    clientType: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    postIndex: string;
    country: string;
    city: string;
    address: string;
    language: string;
    password: string;
    test: boolean;
  }) => {
    const response = await axios.post('/api/v1/clients/registration', {
      client_type: clientType,
      first_name: firstName,
      last_name: lastName,
      email: email,
      phone: phone,
      post_index: postIndex,
      country,
      city,
      address,
      language,
      password,
      test,
    });

    return response.data;
  },

  resetPassword: async ({ email }: { email: string }) =>
    axios.post('/api/v1/clients/password_recovery', {
      email,
    }),

  refreshToken: async ({
    refreshToken,
  }: {
    refreshToken: string;
  }): AxiosPromise<{
    access_token: string;
    refresh_token: string;
    expires_in: string;
    short_living_token: string;
  }> => axios.post(refreshTokenUrl, { refresh_token: refreshToken }),

  getCurrentUser: async () => axios.get('/api/v1/clients/me'),

  updateUser: async (props: {
    clientType?: string;
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
    postIndex?: string;
    country?: string;
    city?: string;
    address?: string;
    language?: string;
    password?: string;
    test?: boolean;
  }): AxiosPromise<ResponseUser> =>
    axios.patch('/api/v1/clients/patch', {
      ...props,
    }),

  updatePhone: async ({ newPhone }: { newPhone: string }) =>
    axios.post('/api/v1/clients/change_phone', {
      new_phone: newPhone,
    }),

  sendConfirmPhoneCode: async () => axios.post('/api/v1/clients/send_code_to_confirm_phone'),

  confirmPhone: async ({ code }: { code: string }) =>
    axios.post('/api/v1/clients/confirm_phone', {
      code,
    }),

  updateEmail: async ({ email }: { email: string }) =>
    axios.post('/api/v1/clients/change_email', {
      new_email: email,
    }),

  confirmEmail: async () => axios.post('/api/v1/clients/send_code_to_confirm_email'),

  getDocuments: async () => {
    const response = await axios.get<AxiosResponse<Document[]>>('/api/v1/documents');
    return response.data;
  },

  changePassword: async ({ password, newPassword }: { password: string; newPassword: string }) =>
    axios.post('/api/v1/clients/update_password', {
      password,
      new_password: newPassword,
    }),
  deactivateAccount: () => axios.post('/api/v1/clients/deactivate'),
  uploadChatFile: async (file: File) => {
    const formData = new FormData();
    await formData.append('file', file);

    return axios.post(WebSocketUploadUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  uploadDriverLicenseFront: async (file: File) => {
    const formData = new FormData();
    await formData.append('file', file);

    return axios.post('/api/v1/documents/media/upload/driver_license_front', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  uploadDriverLicenseBack: async (file: File) => {
    const formData = new FormData();
    await formData.append('file', file);

    return axios.post('/api/v1/documents/media/upload/driver_license_back', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  uploadPassport: async (file: File) => {
    const formData = new FormData();
    await formData.append('file', file);

    return axios.post('/api/v1/documents/media/upload/passport', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  confirmAccount: async ({ code }: { code: string }) => axios.post('/api/v1/clients/confirm_account', {
      code,
    }),

  validateDocuments: async () => axios.post('/api/v1/documents/send_to_verification'),

  setNotificationRead: ({ notificationId }: { notificationId: string }) =>
    axios.post(`${process.env.API_HOST}:8004/api/v1/notifications/${notificationId}/mark_as_read`),
};
