import { createContext, Dispatch, FC, ReactNode, useContext, useEffect, useMemo, useReducer } from 'react';

import { AppReducer, initialState } from './AppReducer';

const STATE_KEY = 'store';

import { ActionTypes, State } from './types';

const AppContext = createContext<{
  state: State;
  dispatch: Dispatch<ActionTypes>;
}>({
  state: initialState,
  dispatch: () => {},
});

type Props = {
  children: ReactNode;
};

export const AppWrapper: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useEffect(() => {
    const stateItem = localStorage.getItem('store');

    if (!stateItem) {
      dispatch({
        type: 'INITIALIZE_STORE',
        payload: initialState,
      });
      return;
    }

    const parsedState = JSON.parse(stateItem);
    const updatedState = {
      ...initialState,
      ...parsedState,
    };

    dispatch({
      type: 'INITIALIZE_STORE',
      payload: updatedState,
    });
  }, []);

  useEffect(() => {
    if (state !== initialState) {
      localStorage.setItem(STATE_KEY, JSON.stringify(state));
    }
  }, [state]);

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export const useAppContext = () => useContext(AppContext);
