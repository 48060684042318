export enum QueryKey {
  'CAR' = 'CAR',
  'CARS' = 'CARS',
  'PAGE' = 'PAGE',
  'CAR_FILTERS' = 'CAR_FILTERS',
  'USER' = 'USER',
  'DOCUMENTS' = 'DOCUMENTS',
  'CURRENT_BOOKINGS' = 'CURRENT_BOOKINGS',
  'PAST_BOOKINGS' = 'PAST_BOOKINGS',
  'FUTURE_BOOKINGS' = 'FUTURE_BOOKINGS',
  'SEO_DATA' = 'SEO_DATA',
  'CAR_CALENDAR' = 'CAR_CALENDAR',
  'CARS_FOR_RENT' = 'CARS_FOR_RENT',
  'CARS_CALC' = 'CARS_CALC',
}

export const ApiHost = `${process.env.API_HOST}`;
export const WebSocketHost = `${process.env.WS_HOST}`;
export const WebSocketUploadUrl = `${process.env.API_HOST}/api/v1/chat/files/upload`;
export const WebSocketChatUrl = `${WebSocketHost}/api/v1/chat/ws`;
export const NotificationAPI = `${process.env.SSE_HOST}/api/v1/notifications/sse`;
