import { useEffect, useRef } from 'react';

export const useClickOutside = ({ onClose }: { onClose: VoidFunction }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (containerRef.current && !containerRef.current.contains(event.target as Node | null)) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return {
    refOutside: containerRef,
  };
};
