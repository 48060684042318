import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { Button } from 'components/Button';

import { CookiesName } from '@/constants/global';
import { Route } from '@/constants/routes';
import { useLogin } from '@/utils/useLogin';

export const EmailPopup = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const { isLogged } = useLogin({});

  const [cookies, setCookies] = useCookies([CookiesName.EMAIL_POPUP, CookiesName.IS_COOKIE_POPUP_OPEN]);

  const isPopupOpen =
    isLogged &&
    cookies[CookiesName.EMAIL_POPUP] !== 'false' &&
    cookies[CookiesName.IS_COOKIE_POPUP_OPEN] === 'false' &&
    !router.pathname.includes(Route.User);

  return isPopupOpen ? (
    <div
      className="bg-gray-100 p-8 fixed bottom-0 inset-x-0
    shadow-[0_20px_20px_10px_rgba(0,0,0,0.3)] z-20">
      <div className="container">
        <div className="mx-auto gap-2 md:gap-10 flex flex-col md:flex-row items-center justify-center">
          <p className="font-medium ">{t('emailPopup.text')}</p>
          <div className="gap-0.5">
            <Button
              className="whitespace-nowrap px-12 mt-3 md:mt-0"
              onClick={() => {
                setCookies(CookiesName.EMAIL_POPUP, 'false', { path: Route.Home });
                router.push(Route.User);
              }}
              title={t('verifyEmail')}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;
};
