import axios from 'axios';

import { Car, LocaleType, PlaceType, RentalObject, RentalResponse, ServerExtraService } from '@/types';
import { prepareExtraServices } from '@/utils';

export const booking = {
  updateBooking: async ({ id, car, ...rest }: RentalObject) =>
    axios.put(`/api/v1/clients/me/rentings/${id}`, {
      ...rest,
      id,
      car: car.id,
    }),
  cancelBooking: async ({ rentId }: { rentId: string }) => axios.post(`/api/v1/clients/me/rentings/${rentId}/cancel`),
  getCurrentBooking: async ({ limit, offset, locale }: { limit: number; offset: number; locale: LocaleType }) => {
    const response = await axios.get<RentalResponse>('/api/v1/clients/me/rentings/current', {
      params: {
        limit,
        offset,
        language: locale,
      },
    });

    return response.data;
  },
  getFutureBooking: async ({ limit, offset, locale }: { limit: number; offset: number; locale: LocaleType }) => {
    const response = await axios.get<RentalResponse>('/api/v1/clients/me/rentings/future', {
      params: {
        limit,
        offset,
        language: locale,
      },
    });

    return response.data;
  },
  getPastBooking: async ({ limit, offset, locale }: { limit: number; offset: number; locale: LocaleType }) => {
    const response = await axios.get<RentalResponse>('/api/v1/clients/me/rentings/past', {
      params: {
        limit,
        offset,
        language: locale,
      },
    });

    return response.data;
  },
  rentCar: async ({
    car,
    startDate,
    startTime,
    endDate,
    endTime,
    addressToDeliverGeo,
    addressToPickupGeo,
    extraServices = {},
  }: {
    car: Car;
    startDate: string;
    startTime: string | null;
    endDate: string | null;
    endTime: string | null;
    addressToDeliverGeo: PlaceType;
    addressToPickupGeo: PlaceType;
    extraServices: { [key: string]: ServerExtraService } | null;
  }) =>
    axios.post('/api/v1/clients/me/rentings', {
      start_date: startDate,
      start_time: startTime,
      end_date: endDate,
      end_time: endTime,
      car: car.id,
      currency: car.currency,
      tariff: car.tariffs[0].id,
      extra_services: prepareExtraServices(extraServices),
      address_to_deliver: addressToDeliverGeo.info?.description,
      deliver_latitude: addressToDeliverGeo.latitude,
      deliver_longitude: addressToDeliverGeo.longitude,
      address_to_pickup: addressToPickupGeo.info?.description,
      pickup_latitude: addressToPickupGeo.latitude,
      pickup_longitude: addressToPickupGeo.longitude,
    }),
};
