import axios, { AxiosRequestHeaders } from 'axios';
import Cookies from 'js-cookie';

import { api } from '@/api';
import { refreshTokenUrl } from '@/api/user';
import { CookiesName } from '@/constants/global';
import { Route } from '@/constants/routes';

export const logout = () => {
  Cookies.set(CookiesName.ACCESS_TOKEN, '');
  Cookies.set(CookiesName.REFRESH_TOKEN, '');
  globalThis.location.href = Route.Home;
};

export const getAccessToken = () => Cookies.get(CookiesName.ACCESS_TOKEN);
export const getRefreshToken = () => Cookies.get(CookiesName.REFRESH_TOKEN);
export const getShortLivedToken = () => Cookies.get(CookiesName.SHORT_LIVED_TOKEN);

export function configureAxios() {
  axios.interceptors.request.use((config) => {
    const accessToken = getAccessToken();
    // eslint-disable-next-line no-param-reassign, max-len
    config.headers = accessToken
      ? ({ Authorization: `Bearer ${accessToken}` } as AxiosRequestHeaders)
      : ({ Authorization: '' } as AxiosRequestHeaders);
    return config;
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error('ERROR AXIOS RESPONSE: ', error);
      const responseData = error?.response?.data || {};

      switch (error.response.status) {
        case 401:
          if (responseData.code === 'bad_token') {
            logout();
            break;
          }
          // access_token is expired
          if (responseData.code === 'token_expired') {
            const refreshToken = getRefreshToken();
            if (error.url === refreshTokenUrl || !refreshToken) {
              logout();
              break;
            }

            const originalRequest = error.config;

            return api.user
              .refreshToken({ refreshToken })
              .then(() => {
                const accessToken = getAccessToken();
                // eslint-disable-next-line no-underscore-dangle
                originalRequest._retry = true;
                originalRequest.headers = { Authorization: `Bearer ${accessToken}` };
                return axios(originalRequest);
              })
              .catch(() => {
                logout();
              });
          }
          break;
        default:
          break;
      }
      return Promise.reject(error);
    }
  );
}
