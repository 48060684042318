export enum UserRoute {
  User = '/user',
  Rental = '/user/rental',
  Payments = '/user/payments',
  Support = '/user/support',
  Documents = '/user/documents',
}

export enum GeneralRoute {
  Home = '/',
  Login = '/login',
  Register = '/register',
  Car = '/car',
  ResetPassword = '/reset-password',
}

export enum BookingRoute {
  BookingSearchCar = '/book-car/search',
  BookingChooseCar = '/book-car/choose-car',
  BookingOrderCar = '/book-car/order-car',
  BookingPayment = '/book-car/payment',
}

export enum RentalRoute {
  Current = '/user/rental/current',
  Future = '/user/rental/future',
  Past = '/user/rental/past',
}

export const Route = { ...GeneralRoute, ...UserRoute, ...BookingRoute };
