import { ServerExtraService } from 'types';

export const prepareExtraServices = (extraServices: { [key: string]: ServerExtraService } | null) =>
  extraServices
    ? Object.values(extraServices)
        .map((item) => ({
          ...item,
          amount: item.count,
        }))
        .filter(({ amount }) => !!amount)
    : null;
