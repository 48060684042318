import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useBoolean } from 'react-use-boolean';
import { useRouter } from 'next/router';

import { api } from '@/api';
import { CookiesName } from '@/constants/global';
import { Route } from '@/constants/routes';

type Props = {
  isDefaultLogged?: boolean;
};

export const useLogin = ({ isDefaultLogged = false }: Props) => {
  const router = useRouter();
  const currentPage = router.route;

  const [cookies, setLoginCookie, removeLoginCookie] = useCookies([
    CookiesName.ACCESS_TOKEN,
    CookiesName.REFRESH_TOKEN,
    CookiesName.SHORT_LIVED_TOKEN,
    CookiesName.NEXT_LOCALE,
    CookiesName.EMAIL_POPUP,
    CookiesName.PHONE_POPUP,
  ]);

  const [isLogged, { on: setIsLogged, off: setUnlogged }] = useBoolean(isDefaultLogged);

  const login = async (
    {
      access_token,
      expires_in,
      refresh_token,
      short_living_token,
    }: {
      access_token: string;
      refresh_token: string;
      expires_in: number;
      short_living_token: string;
    },
    isBooking: boolean = false
  ) => {
    setLoginCookie(CookiesName.ACCESS_TOKEN, access_token, { path: Route.Home, maxAge: expires_in });
    setLoginCookie(CookiesName.REFRESH_TOKEN, refresh_token, { path: Route.Home, maxAge: expires_in });
    setLoginCookie(CookiesName.SHORT_LIVED_TOKEN, short_living_token, { path: Route.Home, maxAge: expires_in });

    setIsLogged();

    const {
      data: { language, is_email_confirmed: isEmailConfirmed, is_phone_confirmed: isPhoneConfirmed },
    } = await api.user.getCurrentUser();

    setLoginCookie(CookiesName.NEXT_LOCALE, language, { path: Route.Home });

    if (!isEmailConfirmed) {
      setLoginCookie(CookiesName.EMAIL_POPUP, 'true', { path: Route.Home });
    }

    if (!isPhoneConfirmed) {
      setLoginCookie(CookiesName.PHONE_POPUP, 'true', { path: Route.Home });
    }

    const prevPath = globalThis?.sessionStorage.getItem('prevPath');

    if (isBooking) {
      return;
    }

    if (prevPath && prevPath.includes('user')) {
      router.push(Route.User, Route.User, { locale: language });
    } else {
      router.push(Route.Home, Route.Home, { locale: language });
    }
  };

  const refreshToken = () => {};

  const logout = () => {
    removeLoginCookie(CookiesName.ACCESS_TOKEN, { path: Route.Home });
    removeLoginCookie(CookiesName.REFRESH_TOKEN, { path: Route.Home });
    removeLoginCookie(CookiesName.SHORT_LIVED_TOKEN, { path: Route.Home });
    setUnlogged();

    if (currentPage.includes(Route.User)) {
      router.push(Route.Home);
    }
  };

  const getAccessToken = () => cookies.access_token;

  useEffect(() => {
    if (!!cookies.access_token) {
      setIsLogged();
    } else {
      setUnlogged();
    }
  }, [cookies]);

  return {
    refreshToken,
    login,
    logout,
    isLogged,
    getAccessToken,
  };
};
