import { Suspense, useEffect, useState } from 'react';
import { CookiesProvider, useCookies } from 'react-cookie';
import TagManager from 'react-gtm-module';
import { Hydrate, QueryClient, QueryClientProvider } from 'react-query';
import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import { Roboto } from 'next/font/google';
import { useRouter } from 'next/router';
import { appWithTranslation } from 'next-i18next';
import { DefaultSeo } from 'next-seo';
import SEO from 'next-seo.config.js';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { CookiesName, GTAG_ID } from '@/constants/global';
import { AppWrapper } from '@/context';
import { EmailPopup } from '@/features/EmailPopup';
import { Footer } from '@/features/Footer';
import { Header } from '@/features/Header';
import { PhonePopup } from '@/features/PhonePopup';

const roboto = Roboto({
  weight: ['300', '400', '500', '700'],
  style: ['normal', 'italic'],
  display: 'swap',
  subsets: ['latin', 'cyrillic'],
  variable: '--font-roboto',
});

const CookiesPopup = dynamic(() => import('@/features/CookiesPopup').then((module) => module.CookiesPopup), {
  ssr: false,
});

import { configureAxios } from 'axios.config';

import '../globals.css';

configureAxios();

const App = ({ Component, pageProps }: AppProps) => {
  const [cookies] = useCookies([CookiesName.DISABLE_GOOGLE_TRACKING]);
  const router = useRouter();

  const storePathValues = () => {
    const storage = globalThis?.sessionStorage;
    if (!storage) return;
    // Set the previous path as the value of the current path.
    const prevPath = storage.getItem('currentPath') as string;
    storage.setItem('prevPath', prevPath);
    // Set the current path value by looking at the browser's location object.
    storage.setItem('currentPath', globalThis.location.pathname);
  };

  useEffect(() => {
    if (cookies.optOutGoogleTracking !== 'true') {
      TagManager.initialize({
        gtmId: GTAG_ID,
      });
    }
  }, []);

  useEffect(() => storePathValues, [router.asPath]);

  const [queryClient] = useState(() => new QueryClient());

  const theme = createTheme({
    typography: {
      fontFamily: roboto.style.fontFamily,
    },
  });

  return (
    <CookiesProvider>
      <Suspense>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ThemeProvider theme={theme}>
              <AppWrapper>
                <style global jsx>{`
                  html {
                    font-family: ${roboto.style.fontFamily};
                  }
                `}</style>
                <DefaultSeo {...SEO} />
                <div className="min-h-screen md:grid">
                  <Header isDefaultLogged={pageProps.isDefaultLogged} />
                  <Component {...pageProps} />
                  <Footer />
                  <CookiesPopup isCookiesPopup={pageProps.isCookiesPopup} />
                  <EmailPopup />
                  <PhonePopup />
                  <div id="modal-root"></div>
                </div>
              </AppWrapper>
            </ThemeProvider>
          </Hydrate>
        </QueryClientProvider>
      </Suspense>
    </CookiesProvider>
  );
};

export default appWithTranslation(App);
