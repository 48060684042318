module.exports = {
  title: 'Title of your website', //TODO: set real data
  titleTemplate: `%s | ${process.env.NEXT_PUBLIC_WEBSITE_NAME}`, //TODO: set real site name
  defaultTitle: 'Title of your website', //TODO: set default title
  description: 'Description of your website', //TODO: set description
  themeColor: '#007aff',
  // eslint-disable-next-line max-len
  keywords:
    'car hire, cheap car hire, car rental,  rent a car, car rentals, hire car, cheap car rentals, cheap car rental, carrentals, rent car, car hire comparison, carrental, carhire, compare car hire, car rental comparison, rentalcars, rental cars',
  additionalLinkTags: [
    {
      rel: 'icon',
      href: '/favicon.ico',
    },
    {
      rel: 'apple-touch-icon',
      href: '/64.png',
      sizes: '64x64',
    },
    {
      rel: 'apple-touch-icon',
      href: '/128.png',
      sizes: '128x128',
    },
    {
      rel: 'apple-touch-icon',
      href: '/256.png',
      sizes: '256x256',
    },
    {
      rel: 'apple-touch-icon',
      href: '/512.png',
      sizes: '512x512',
    },
    {
      rel: 'manifest',
      href: '/manifest.json',
    },
  ],
  openGraph: {
    type: 'website',
    url: process.env.NEXT_PUBLIC_DOMAIN_URL, //TODO: add real url
    title: 'Open Graph Title', //TODO: set default title
    images: [
      {
        url: '/og-image.jpg', //TODO: add image
        width: 1200,
        height: 630,
        alt: 'Og Image Alt', //TODO: set default title
      },
    ],
  },
};
