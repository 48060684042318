import { FC, memo, useCallback } from 'react';
import { useCookies } from 'react-cookie';
import { useMutation } from 'react-query';
import { useRouter } from 'next/router';
import cn from 'classnames';

import { api } from '@/api';
import { CookiesName, DEFAULT_LOCALE, LOCALES } from '@/constants/global';
import { Route } from '@/constants/routes';
import { useLogin } from '@/utils/useLogin';

type Props = {
  onClick: VoidFunction;
  isDefaultLogged: boolean;
};

export const LanguagesSwitch: FC<Props> = memo(({ onClick, isDefaultLogged }) => {
  const router = useRouter();
  const [, setLocale] = useCookies<
    CookiesName.NEXT_LOCALE,
    {
      NEXT_LOCALE?: string;
    }
  >([CookiesName.NEXT_LOCALE]);

  const { isLogged } = useLogin({ isDefaultLogged });

  const locales = (router.locales as typeof LOCALES) || LOCALES;
  const currentLocale = router.locale || DEFAULT_LOCALE;

  const { mutate: handleChangeProfileLanguage } = useMutation(api.user.updateUser);

  const handleChangeLanguage = useCallback(
    (locale: string) => {
      if (isLogged) {
        handleChangeProfileLanguage({ language: locale });
      }
      setLocale(CookiesName.NEXT_LOCALE, locale, { path: Route.Home });
      router.push(router.asPath, router.asPath, { locale });
      onClick();
    },
    [onClick]
  );

  return (
    <div className="flex font-bold">
      {locales.map((locale) => (
        <button
          className={cn('border-b', 'mx-2', 'text-sm', 'uppercase', 'transition', 'hover:border-gray-500', {
            'border-black': currentLocale === locale,
            'text-gray-500': currentLocale !== locale,
            'border-transparent': currentLocale !== locale,
          })}
          key={locale}
          onClick={() => handleChangeLanguage(locale)}
          type="button">
          {locale}
        </button>
      ))}
    </div>
  );
});
