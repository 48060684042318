export const LOCALES = ['en', 'fr'];
export const DEFAULT_LOCALE = 'en';
export const DEFAULT_COUNTRY = 'VA';

export const GTAG_ID = 'G-0FNEBZ7K4B';
export const GA_ID = '0FNEBZ7K4B';

export enum CookiesName {
  NEXT_LOCALE = 'NEXT_LOCALE',
  DISABLE_GOOGLE_TRACKING = 'optOutGoogleTracking',
  DISABLE_GOOGLE_ANALITICS = '_ga',
  IS_COOKIE_POPUP_OPEN = 'IS_COOKIE_POPUP_OPEN',
  ACCESS_TOKEN = 'access_token',
  SHORT_LIVED_TOKEN = 'short_living_token',
  REFRESH_TOKEN = 'refresh_token',
  EMAIL_POPUP = 'email_popup',
  PHONE_POPUP = 'phone_popup',
}

export const FULL_DATE_FORMAT = 'dd MMMM yyyy';
export const SHORT_FULL_DATE_FORMAT = 'dd.MM.yyyy';
export const SERVER_FULL_DATE_FORMAT = 'yyyy-MM-DD';
export const FE_DATE_FORMAT = 'yyyy-MM-dd';
export const FULL_TIME_WITH_HOURS = 'yyyy-MM-DD hh:mm';
export const HOURS_TIME = 'hh:mm';
export const HOURS_TIME_WITH_NOON = 'hh:mm a';
export const DATE_FULL_MONTH = 'eeee';
export const DATE_DATE_AND_MONTH = 'dd MMMM';
export const DATE_YEAR = 'yyyy';

export const GOOGLE_API_KEY = 'AIzaSyBCzGgx9rBeb5G5iFXHUEGh86QEGB_uPHo';

export const DEFAULT_LIMIT = 10;

export const DEFAULT_CURRENCY = 'eur';

export const PASSWORD_STRENGTH_SCORE = 3;
export const OLD_EVENT_SOURCE_MESSAGES_COUNT = 100;
