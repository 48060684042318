import dynamic from 'next/dynamic';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { Route } from '@/constants/routes';

const appleStore = require('assets/icons/appleStore.svg');
const googlePlay = require('assets/icons/googlePlay.svg');

const CookiesButton = dynamic(() => import('@/features/CookiesButton').then((module) => module.CookiesButton), {
  ssr: false,
});

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <div
      className="flex sm:gap-0 gap-5 container mx-auto px-6 lg:px-0 py-6
      lg:py-12 border-t border-gray-200 mt-4 md:mt-10 xl:mt-20">
      <div className="flex flex-col md:flex-row w-1/2 md:w-3/5 gap-4 md:gap-0">
        <div className="w-full md:w-1/3">
          <p className="mb-2 font-medium">{t('footer.ourOffer')}</p>
          <div className="font-light">
            <a className="text-sm md:text-base block transition hover:opacity-70" href={Route.Home}>
              {t('footer.discoverOurOffer')}
            </a>
            <a className="text-sm md:text-base block transition hover:opacity-70" href={Route.Home}>
              {t('footer.businessCarRental')}
            </a>
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <p className="mb-2 font-medium">{t('footer.about')}</p>
          <div className="font-light">
            <a className="text-sm md:text-base block transition hover:opacity-70" href={Route.Home}>
              {t('footer.howItWorks')}
            </a>
            <a className="text-sm md:text-base block transition hover:opacity-70" href={Route.Home}>
              {t('footer.mobileApps')}
            </a>
            <a className="text-sm md:text-base block transition hover:opacity-70" href={Route.Home}>
              {t('footer.becomeAPartner')}
            </a>
            <a className="text-sm md:text-base block transition hover:opacity-70" href={Route.Home}>
              {t('footer.helpCenter')}
            </a>
          </div>
        </div>
        <div className="w-full md:w-1/3">
          <p className="mb-2 font-medium">{t('footer.ourCars')}</p>
          <div className="font-light">
            <a className="text-sm md:text-base block transition hover:opacity-70" href={Route.Home}>
              Audi A3
            </a>
            <a className="text-sm md:text-base block transition hover:opacity-70" href={Route.Home}>
              Audi Q3
            </a>
            <a className="text-sm md:text-base block transition hover:opacity-70" href={Route.Home}>
              BMW 1 Series
            </a>
            <a className="text-sm md:text-base block transition hover:opacity-70" href={Route.Home}>
              BMW X1
            </a>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-2 gap-4 col-span-1 md:col-span-3 md:pt-10 lg:pt-0 lg:col-span-1">
        <a className="w-28 md:w-auto self-end md:self-center" href={Route.Home}>
          <Image alt={t('appleStore')} className="w-full transition hover:opacity-70" src={appleStore} />
        </a>
        <a className="w-28 md:w-auto self-start md:self-center" href={Route.Home}>
          <Image alt={t('googlePlay')} className="w-full transition hover:opacity-70" src={googlePlay} />
        </a>
        <div />
        <CookiesButton />
      </div>
    </div>
  );
};
